import React,{useState} from 'react';
import Modal from "@mui/material/Modal";
import { CircularProgress } from '@mui/material';
import Input from '../BaseComponents/Input';
import Logo from "../../assets/logoPng.png";

const CommercialModal = ({open,isLoading, handleClose, handleSubmit}) => {
  const [data,setData] = useState({name:"",phone:""})
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="outline-none absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white px-8 py-4 rounded-md w-[90vw] md:w-[75vw] lg:w-[50vw]">
        <div className="flex justify-center items-center mt-1 mb-4">
              <img
                src={Logo}
                className="grow-0 shrink-0  h-12 translate-x-[-2]"
                alt="Bëtacar"
              />
            </div>
          <div className="text-center text-primary font-bold  text-xl">
            BËTACAR 
          </div>
          <p
            className="mb-4  text-center text-slate-900 font-bold"
          >
            Afin d'améliorer la qualité du service Bëtacar, il vous est demandé de remplir le formulaire çi-dessous  
          </p>
         
          <div className="w-80 mx-auto">
            
                <Input
                  title="Nom"
                  name="name"
                  value={data.name}
                  handleChange={(e) => setData({...data,name:e.target.value})}
                  
                />
                <Input
                  title="Numéro de télephone"
                  name="phone"
                  type="number"
                  value={data.phone}
                  handleChange={(e) => setData({...data,phone:e.target.value})}
                  
                />
          </div>
          <button
            className="flex items-center mx-auto mt-4 bg-primary text-white px-4 py-2 rounded-md mt-2 hover:cursor-pointer hover:bg-secondary"
            onClick={handleSubmit}
          >
            {isLoading ? (<CircularProgress size={22} sx={{color:"#E66C2F"}}/>):"Soumettre"}
          </button>
        </div>
      </Modal>
  )
}

export default CommercialModal