import React from "react";
import { motion } from "framer-motion";
import Logo from "../../assets/taxi.jpg";

const imgContainerVariants = {
  hidden: {
    x: "100vw",
    opacity: 0,
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 2,
    },
  },
};

const containerVariants = {
  hidden: {
    x: "-100vw",
    opacity: 0,
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 1.5,
    },
  },
};

const HeroSection = () => {
  return (
    <div className="flex flex-col md:flex-row md:h-[60vh] py-4 md:py-8 w-[90%] mx-auto overflow-hidden">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="md:w-[50%] lg:w-[40%] shrink-0 flex flex-col items-center md:items-start justify-center px-6 "
      >
        <p className="text-2xl md:text-4xl text-primary font-bold">
          Nos services
        </p>
        <p className="mt-2 text-[#373F41] font-semibold text-md md:text-xl text-center md:text-start">
          Bëtacar Drive est la première plateforme de réservation de taxis en
          commun, à tarifs grand public avec garantie de confort, rapidité et de
          sécurité absolue
        </p>
      </motion.div>
      <motion.div
        variants={imgContainerVariants}
        initial="hidden"
        animate="visible"
        className="md:w-[50%] lg:w-[60%] shrink-0 flex justify-end items-center "
      >
        <img src={Logo} alt="logo " className="shrink-0" />
      </motion.div>
    </div>
  );
};

export default HeroSection;
