import React from 'react';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import SearchContainer from './container/SearchContainer';

const SearchLocation = () => {
  return (
    <section>
      <Navbar/>
      <SearchContainer/>
      <Footer/>
    </section>
  )
}

export default SearchLocation