import React from "react";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";

const Footer = () => {
  return (
    <section className="bg-primary">
      <div className="w-[90%] mx-auto flex justify-between items-center py-2">
        <p className="font-medium text-white">Copyright©2023</p>
        <div className="flex items-center h-full pt-1 [&>*]:ml-4 md:[&>*]:ml-8 [&>*]:text-2xl [&>*]:cursor-pointer text-white ">
          <Link
            to="https://www.facebook.com/betacardrive?mibextid=LQQJ4d"
            target="_blank"
            className="flex items-center"
          >
            <AiFillFacebook />
          </Link>

          <Link
            to="https://www.linkedin.com/in/frankee-seme-9a65109b"
            target="_blank"
            className="flex items-center"
          >
            <AiFillLinkedin />
          </Link>
          <Link
            to="https://t.me/+UR8bEHHfFTNwNK8A"
            target="_blank"
            className="flex items-center"
          >
            <BsTelegram />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
