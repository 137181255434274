import React from 'react';
import noLocation from "../../assets/noLocation.png"

const NoRoute = () => {
  return (
    <div className='flex flex-col items-center'>
      <img src={noLocation} alt="no location" className='w-60 h-40'/>
      <p className='mt-2 text-lg font-bold'>Aucun chemin trouvé entre ces deux localisations</p>
    </div>
  )
}

export default NoRoute