import React,{useEffect} from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import Worker from "../../../assets/procab.png";

const ProcabBanner = () => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  return (
    <div className="flex flex-col md:flex-row md:h-[60vh] py-4 md:py-8 overflow-hidden">
      <div data-aos="fade-right" className="aos md:w-[50%] lg:w-[40%] shrink-0 flex flex-col items-center md:items-start justify-center px-6 ">
        <p className="uppercase text-xl md:text-2xl text-primary font-extrabold text-center md:text-start">
          Procab
        </p>
        <p className="mt-2 font-bold text-[#47585E] text-md md:text-lg text-center md:text-start">
          Se sentir bien au travail commence aussi par la ponctualité et la sécurité des employés hors de l’entreprise.
        </p>
      </div>
      <div className=" md:w-[50%] lg:w-[60%] shrink-0 flex justify-end items-center md:translate-x-[8vw]">
        <img src={Worker} alt="logo " className="shrink-0 scale-[0.8]" />
      </div>
    </div>
  )
}

export default ProcabBanner