import React from "react";

const R2SHowItWorks = () => {
  return (
    <div>
      <p className="text-primary md:text-2xl font-extrabold text-center my-4">
        Comment ça marche ?
      </p>
      <p className=" md:w-[85%] lg:w-[70%] mx-auto text-center text-[#47585E] font-medium">
        Avec nos puissants outils, nous analysons, exploitons et classons les
        meilleures affinités de trajets entre les différents logements pour être
        imbattables dans la dépose et ramasse de vos trésors fournissant ainsi un 
déploiement à 85% plus efficace que le bus scolaire.
      </p>
    </div>
  );
};

export default R2SHowItWorks;
