import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ParentsInfo = ({ data, handleChange }) => {
  const PersonalInfo = [
    {
      label: "Noms et Prénoms",
      name: "username",
      type: "text",
      value: data.username,
    },
    {
      label: "Numéro de CNI",
      name: "numeroCni",
      type: "text",
      value: data.numeroCni,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      value: data.email,
    },
    {
      label: "Numéro de téléphone principal",
      name: "telephone",
      type: "number",
      value: data.telephone,
    },
    {
      label: "Quartier",
      name: "quartier",
      type: "text",
      value: data.quartier,
    },
    {
      label: "Ville",
      name: "ville",
      type: "text",
      value: data.ville,
    },
  ];

  /* const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red',
      },
      '& .MuiOutlinedInput-input::placeholder': {
        color: 'gray',
      },
      '& .MuiOutlinedInput-input.Mui-focused::placeholder': {
        color: 'blue',
      },
    },
  }); */
  return (
    <section>
      <p className="text-center font-bold my-2 text-primary text-lg">Informations sur le parent ou tuteur</p>
      <p className="text-black/80 font-bold text-md mb-4">
        Informations personnelles
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5   [&>*]:my-1 ">
        {PersonalInfo.map((info, id) => {
          return (
            <TextField
              key={id}
              sx={{ width: "100%" }}
              id="outlined-basic"
              label={info.label}
              name={info.name}
              type={info.type}
              value={info.value}
              onChange={handleChange}
              variant="outlined"
              size="small"
            />
          );
        })}
      </div>
      <p className="text-black/80 font-bold text-md mb-4 my-8">
        Informations complémentaires
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5   [&>*]:my-1 ">
        <TextField
          sx={{ width: "100%" }}
          id="outlined-basic"
          label="Nom du récupérateur des enfants"
          name="personneRecupereEnfants"
          type="text"
          value={data.personneRecupereEnfants}
          onChange={handleChange}
          variant="outlined"
        />
        <FormControl fullWidth>
          <InputLabel id="num-kids-label">
            Enfants à transporter (maximum 5)
          </InputLabel>
          <Select
            label="Enfants à transporter (maximum 5)"
            labelId="num-kids-label"
            id="num-kids"
            name="nombreEnfant"
            value={data.nombreEnfant}
            onChange={handleChange}
          >
            <MenuItem value={1}>1 enfant</MenuItem>
            <MenuItem value={2}>2 enfants</MenuItem>
            <MenuItem value={3}>3 enfants</MenuItem>
            <MenuItem value={4}>4 enfants</MenuItem>
            <MenuItem value={5}>5 enfants</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="live-kids-label">
            Vivez vous avez tous vos enfants ?
          </InputLabel>
          <Select
            label="Vivez vous avez tous vos enfants ?"
            labelId="live-kids-label"
            id="live-kids"
            name="vieAvecToutMesEnfants"
            value={data.vieAvecToutMesEnfants}
            onChange={handleChange}
          >
            <MenuItem value={true}>oui</MenuItem>
            <MenuItem value={false}>Non</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{
            width: "100%",

            "& .MuiOutlinedInput-input::placeholder": {
              color: "black",
              opacity: 0.6,
            },
          }}
          id="outlined-basic"
          placeholder="Description exacte du secteur d'habitation"
          name="secteurHabitation"
          type="text"
          multiline
          rows={5}
          value={data.secteurHabitation}
          onChange={handleChange}
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-input::placeholder": {
              color: "black",
              opacity: 0.6,
            },
          }}
          id="outlined-basic"
          placeholder="Décrivez l'emplacement sûr et proche de la route et de votre maison 
            où vous voulez que nous prenions et déposions vos enfants matin et soir."
          name="detailPourDeposerEnfant"
          type="text"
          multiline
          rows={5}
          value={data.detailPourDeposerEnfant}
          onChange={handleChange}
          variant="outlined"
          size="small"
        />
      </div>
    </section>
  );
};

export default ParentsInfo;
