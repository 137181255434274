import React,{useEffect} from 'react';
import {BsApple} from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";
import Phone from "../../assets/phone.png";
import googlePlay from "../../assets/googlePlay.png";

const Betacar = () => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  return (
    <div className="flex flex-col md:flex-row md:h-[60vh] py-4 md:py-8 overflow-hidden  w-[90%] mx-auto">
      <div data-aos="fade-right" className="aos md:w-[50%] lg:w-[40%] shrink-0 flex flex-col items-center md:items-start justify-start px-6 ">
        <p className="uppercase text-xl md:text-2xl text-primary font-extrabold text-center md:text-start">
          BËTACAR
        </p>
        <p className="mt-2 font-bold text-[#47585E] text-md md:text-lg text-center md:text-start">
        Avec l’appli Bëtacar, configurez votre trajet journalier, faites valider votre budget et recherchez vos voisins qui ont des paramètres de trajets similaires au votre.
        </p>
        <div className='mt-2 md:flex md:[&>*]:mr-6 [&>*]:w-[170px]'>
          <div className='mt-2 bg-[#191919] hover:cursor-pointer hover:bg-[#212020] py-2 px-4 flex items-center text-white rounded-md'>
              <BsApple className='mr-2 text-3xl'/>
              <div>
                <p className='text-sm font-thin'>Disponible sur</p>
                <p className='font-bold'>App Store</p>
              </div>
          </div>
          <div className=' mt-2 bg-[#191919] hover:cursor-pointer hover:bg-[#212020] py-2 px-4 flex items-center text-white rounded-md'>
              <img src={googlePlay} alt='google play button' className='w-[30px] h-[30px] mr-2'/>
              <div>
                <p className='text-sm font-thin'>Disponible sur</p>
                <p className='font-bold'>Google Play</p>
              </div>
          </div>
        </div>
      </div>
      <div className=" md:w-[50%] lg:w-[60%] shrink-0 flex justify-end items-center md:translate-x-[8vw]">
        <img src={Phone} alt="logo " className="shrink-0 scale-[0.8]" />
      </div>
    </div>
  )
}

export default Betacar