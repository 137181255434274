import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import AddModifyModal from "../AddModifyModal";
import DeleteModal from "../DeleteModal";
import ChildPic from "../../../assets/kid.png";
import 'react-toastify/dist/ReactToastify.css';

const ChildrenInfo = ({ childrenNumber,data, addChild, deleteChild, modifyChild }) => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [childId, setChildId] = useState(null);
  const [modify, setModify] = useState(false);
  const [childInfo, setChildInfo] = useState({
    username: "",
    niveauScolaire: "",
    quartier: "",
    etablissement: "",
    ville: "",
    lieuDeRamassage: "",
  });
  const ChildFormInfo = [
    {
      label: "Noms et Prénoms",
      name: "username",
      value: childInfo.username,
    },
    {
      label: "Niveau scolaire",
      name: "niveauScolaire",
      value: childInfo.niveauScolaire,
    },
    {
      label: "Etablissement",
      name: "etablissement",
      value: childInfo.etablissement,
    },
    {
      label: "Quartier de résidence",
      name: "quartier",
      value: childInfo.quartier,
    },
    {
      label: "Ville",
      name: "ville",
      value: childInfo.ville,
    },
    {
      label: "Lieu de ramassage",
      name: "lieuDeRamassage",
      value: childInfo.lieuDeRamassage,
    },
  ];

  const handleClose = () => {
    setChildInfo({
      username: "",
      niveauScolaire: "",
      quartier: "",
      etablissement: "",
      ville: "",
      lieuDeRamassage: "",
    });
    setOpen(false);
  };

  const ModifyModal = (id) => {
    setChildInfo(data[id]);
    setChildId(id);
    setModify(true);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDelete(false);
  };

  const handleOpen=() => {
    setModify(false);
    setChildId(null);
    setOpen(true);
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setChildInfo({ ...childInfo, [name]: value });
  };

  const isNull = () => {
    if (
      childInfo.username &&
      childInfo.etablissement &&
      childInfo.lieuDeRamassage &&
      childInfo.niveauScolaire &&
      childInfo.quartier &&
      childInfo.ville
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleAdd = () => {
    if (!isNull()) {
      addChild(childInfo);
      handleClose();
    } else {
      toast.error("Veuillez remplir tous les champs", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  const handleModify = () => {
    if (
      !isNull() &&
      !(
        childInfo.username === data[childId].username &&
        childInfo.etablissement === data[childId].etablissement &&
        childInfo.lieuDeRamassage === data[childId].lieuDeRamassage &&
        childInfo.niveauScolaire === data[childId].niveauScolaire &&
        childInfo.quartier === data[childId].quartier &&
        childInfo.ville === data[childId].ville
      )
    ) {
      modifyChild(childInfo, childId);
      handleClose();
    } else {
      toast.error("Aucune information modifiée", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  return (
    <div className="mb-4 ">
      <div
        className={`${data.length > 0 && "hidden"} flex flex-col items-center`}
      >
        <img src={ChildPic} className=" w-40 h-60" alt="child pic" />
        <div className="translate-y-[-20px] text-center flex flex-col items-center">
          <p className="font-bold text-red-400 text-xl ">
            {" "}
            Aucun enfant ajouté !
          </p>
          <button
            className="flex items-center bg-green-600 text-white px-3 py-1 rounded-full mt-2 hover:cursor-pointer hover:bg-green-500"
            onClick={handleOpen}
          >
            <BsPlusCircle className="mr-1" /> Nouvel enfant
          </button>
        </div>
      </div>

      {/**lorsqu'un nouvel enfant a déjà été ajouté */}
      <div
        className={`${
          data.length === 0 && "hidden"
        }  mx-auto w-[100%] lg:w-[80%]`}
      >
        <div
          className={`${
            data.length === childrenNumber && "hidden"
          } text-end mb-4 text-green-600 font-bold `}
        >
          <span
            className=" py-1 px-2 rounded-full hover:cursor-pointer hover:bg-green-600/20"
            onClick={handleOpen}
          >
            + Ajouter un enfant
          </span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {data.map((elt, id) => {
            return (
              <div
                className="shadow shadow-md border border-primary rounded-xl  py-2 px-4 [&>*]:break-all"
                key={id}
              >
                <p className="font-bold text-lg text-center text-primary">
                  Enfant {id + 1}
                </p>
                <div className="font-medium ">
                  <p>
                    Nom et prenom:{" "}
                    <span className="text-secondary font-bold text-lg">
                      {elt.username}
                    </span>
                  </p>
                  <p>
                    Niveau:{" "}
                    <span className="text-secondary font-bold text-lg">
                      {elt.niveauScolaire}
                    </span>
                  </p>
                  <p>
                    Quartier:{" "}
                    <span className="text-secondary font-bold text-lg">
                      {elt.quartier}
                    </span>
                  </p>
                  <p>
                    Etablissement:{" "}
                    <span className="text-secondary font-bold text-lg">
                      {elt.etablissement}
                    </span>
                  </p>
                  <p>
                    Ville:{" "}
                    <span className="text-secondary font-bold text-lg">
                      {elt.ville}
                    </span>
                  </p>
                  <p>
                    Lieu:{" "}
                    <span className="text-secondary font-bold text-lg">
                      {elt.lieuDeRamassage}
                    </span>
                  </p>
                </div>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-5 [&>*]:text-white hover:[&>*]:cursor-pointer  [&>*]:rounded-md [&>*]:p-1 [&>*]:text-center">
                  <button
                    className="bg-green-500 hover:bg-green-500/80"
                    onClick={() => ModifyModal(id)}
                  >
                    Modifier
                  </button>
                  <button
                    className="bg-red-500  hover:bg-red-500/80"
                    onClick={() => {
                      setOpenDelete(true);
                      setChildId(id);
                    }}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/**Modal pour ajouter un nouvel enfant */}
      <AddModifyModal
        open={open}
        handleClose={handleClose}
        data={ChildFormInfo}
        handleChange={handleChange}
        handleAdd={handleAdd}
        handleModify={handleModify}
        modify={modify}
      />
      <DeleteModal
        openDelete={openDelete}
        handleCloseDeleteModal={handleCloseDeleteModal}
        id={childId}
        handleDelete={deleteChild}
      />
      <ToastContainer/>
    </div>
  );
};

export default ChildrenInfo;
