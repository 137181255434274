import React,{useEffect} from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import { FaRegHandshake ,FaFeatherAlt,} from "react-icons/fa";
import {RiRoadsterFill} from "react-icons/ri";
import {GiRoad} from "react-icons/gi";

const ProcabFunctionalities = () => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  const services = [
    {
      icon: <GiRoad />,
      name: "Trajet direct sans contour",
     
    },
    {
      icon: <FaRegHandshake />,
      name: "Sûr et fiable",
    },
    {
      icon: <FaFeatherAlt />,
      name: "Confort",
    },
    
    {
      icon: <RiRoadsterFill />,
      name: "Chauffeur fun et professionel",
    },
  ];

  return (
    <div>
      <p className="text-primary md:text-2xl font-extrabold text-center my-4">
        Pourquoi choisir ProCab
      </p>
      <p className=" md:w-[85%] lg:w-[70%] mx-auto text-center text-[#47585E] font-medium">
      La ponctualité est le premier pas vers le développement des activités d’une structure, assurer la sécurité des employés au retour est une responsabilité sociale qui leur fait avoir plus confiance à l’entreprise. Notre système de Dépose-Ramasse exploite jusqu’au dernier kilomètre pour pousser leur sécurité encore plus loin,
      </p>
      <div className="flex flex-wrap justify-center [&>*]:mx-2  my-2">
        {services.map((service, id) => {
          return (
            <div
              key={id}
              data-aos="fade-up"
              className="aos flex flex-col items-center w-[250px] p-2 text-center my-2 text-[#47585E]"
            >
              <p className=" font-extrabold text-5xl ">{service.icon}</p>
              <p className="text-xl mt-1 font-bold">{service.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default ProcabFunctionalities