import React from "react";
import Navbar from "../../Layout/Navbar";
import SubscriptionsContainer from "./Container/SubscriptionsContainer";
import Footer from "../../Layout/Footer";

const Subscriptions = () => {
  return (
    <section className="">
      <Navbar />
      <SubscriptionsContainer />
      <Footer />
    </section>
  );
};

export default Subscriptions;
