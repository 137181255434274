import React from "react";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import Logo from "../../../assets/logoPng.png";

const ConfirmationStep = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto">
        <div className="flex justify-center items-center">
          <img
            src={Logo}
            className="grow-0 shrink-0  h-12 translate-x-[-2]"
            alt="Bëtacar"
          />
        </div>

        <div className="w-[100%] lg:w-[70%] lg:w-[50%] mx-auto">
        <p className="text-[#47585E]  text-center text-sm md:text-lg font-bold my-4">Nous vous remercions d'avoir souscrit au programme <span className="text-secondary" >Ride 2 School</span> de Bëtacare drive.Vous serez contacté dans les jours qui suivent par notre équipe.Un email vous a été envoyé concernant les différentes informations relatives à votre souscription</p> 
        </div>
       <p className="text-center text-primary text-lg hover:underline hover:cursor-pointer font-bold flex items-center justify-center" onClick={() => navigate("/")}>
        <FaHome className="mr-1"/>  <span>Retour à l'accueil</span>
       </p>
        
    </div>
  );
};

export default ConfirmationStep;
