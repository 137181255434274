import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Services from "../Pages/Services/Services";
import Subscriptions from "../Pages/Subscriptions/Subscriptions";
import SearchLocation from "../Pages/Search/SearchLocation";

const Router = () => {
    return (
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />

          <Route path="services" element={<Services />} />
          <Route path="subscription/*" element={<Subscriptions/>}/>
          <Route path="search" element={<SearchLocation/>}/>
        
        </Route>
        <Route path="/*" element={<Navigate to="/"/>}/>
      </Routes>
    );
  };
  
  export default Router;
  