import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { RiDirectionLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { VscPerson } from "react-icons/vsc";
import { FaBus } from "react-icons/fa";
import { getPrice } from "./Constants";
import NoRoute from "./NoRoute";

const center = { lat: 3.868992, lng: 11.517649 };

const MapContainer = ({ data, departure, destination }) => {
  const navigate = useNavigate();
  const [price, setPrice] = useState(null);
  useEffect(() => {
    if (data.distance) {
      const res = getPrice(parseFloat(data.distance.replace("km", "").trim()));
      console.log("res is ", res);
      setPrice(res);
    }
  }, [data.distance]);
  return (
    <section className="w-[90%] mx-auto py-6">
      {data.error ? (
        <NoRoute />
      ) : (
        <div className=" min-h-[60vh] mt-2">
          <p className="mb-6 text-center text-md md:text-lg font-bold capitalize text-primary">
            {departure.current && departure.current.value} -{" "}
            {destination.current && destination.current.value}
          </p>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "60vh", zIndex: 0 }}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: true,
            }}
          >
            <Marker position={center} />
            {data.directions && (
              <DirectionsRenderer directions={data.directions} />
            )}
          </GoogleMap>
          <div className="mt-6 text-center">
            <div className="flex justify-center [&>*]:mx-4">
              <div className="text-center">
                <div className="border-2 border-primary text-primary rounded-full p-3">
                  <RiDirectionLine className="text-2xl" />
                </div>
                <p className="mt-1">{data.distance}</p>
              </div>

              <div className="text-center">
                <div className="border-2 border-primary text-primary rounded-full p-3 w-fit mx-auto">
                  <FaBus className="text-2xl" />
                </div>
                <p className="mt-1">
                  {data.duration && data.duration.replace("mins", "")} minutes
                </p>
              </div>
              <div className="text-center">
                <div className="mx-auto border-2 border-primary text-primary rounded-full p-3 w-fit">
                  <VscPerson className="text-2xl" />
                </div>
                <p className="mt-1">
                  {data.passengers && data.passengers} passagers
                </p>
              </div>
            </div>
            <div className="md:flex justify-center my-4 ">
              <div className="md:mr-5">
                <p className="mt-4 text-secondary font-bold ">
                  <span className="text-5xl ">
                    {price &&
                      parseInt(
                        price.simple * (data.passengers ? data.passengers : 1)
                      )}
                  </span>
                  <span className="text-xl">frc</span>
                </p>
                <p className="text-primary font-bold text-lg">Aller simple</p>
              </div>
              <div className="md:ml-5">
                <p className="mt-4 text-secondary font-bold">
                  <span className="text-5xl ">
                    {price &&
                      parseInt(
                        price.both * (data.passengers ? data.passengers : 1)
                      )}
                  </span>
                  <span className="text-xl">frc</span>
                </p>
                <p className="text-primary font-bold text-lg">Aller-Retour</p>
              </div>
            </div>
            <div className="mt-4">
              <button
                className="bg-primary py-2 px-6 rounded-md mx-auto text-white font-bold hover:cursor-pointer hover:bg-primary/90"
                onClick={() => navigate("/subscription/minicom")}
              >
                Souscrire
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MapContainer;
