import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import MapContainer from "../../../Components/Search/MapContainer";
import SearchBanner from "../../../Components/Search/SearchBanner";
import taxi from "../../../assets/taxi.png";

const SearchContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const smScreen = useMediaQuery("(max-width:767px)");
  const mdScreen = useMediaQuery("(min-width:768px)");
  const lgScreen = useMediaQuery("(min-width:1024px)");

  const [passengers, setPassengers] = useState({ open: false, count: 1 });
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mapParams, setMapParams] = useState("");
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  const departure = useRef();
  const destination = useRef();
  /* useEffect(() => {
    setTimeout(() => {
      if (!isLoaded) {
        //alert("erreur lors du chargement de l'api google map")
      }
    }, 5000);
  }, []); */

  useEffect(() => {
    if (openModal) {
      setOpenModal(false);
    }
  }, [mdScreen, lgScreen]);

  useEffect(() => {
    const departureValue = searchParams.get("departure");
    const destinationValue = searchParams.get("destination");
    const passengersValue = searchParams.get("passengers");

    const calculateRoute = async (departure, destination) => {
      const google = window.google;
      const directionsService = new google.maps.DirectionsService();
      try {
        const results = await directionsService.route({
          origin: departure,
          destination: destination,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        });
        setMapParams({
          ...mapParams,
          directions:results,
          distance:results.routes[0].legs[0].distance.text,
          duration:results.routes[0].legs[0].duration.text,
          departure: departureValue && departureValue,
          destination: destinationValue && destinationValue,
          passengers: passengersValue && parseInt(passengersValue),
          error: false,
          errorMessage: "Trajet existant",
        });
      } catch (error) {
        setMapParams({
          ...mapParams,
          error: true,
          errorMessage: "Trajet inexistant",
        });
      }
    };

    if (!departureValue || !destinationValue || !passengersValue) {
      navigate("/search");
    } else {
      setTimeout(() => {
        departure.current.value = departureValue;
        destination.current.value = destinationValue;
      }, 1000);
      setPassengers({ ...passengers, count: parseInt(passengersValue) });
      setTimeout(() => {
        calculateRoute(departureValue, destinationValue);
      }, 1000);
    }
  }, [searchParams, navigate]);

  const openPassengers = () => {
    if (smScreen) {
      setOpenModal(true);
    } else {
      setPassengers({ ...passengers, open: true });
    }
  };

  const closePassengers = () => {
    if (passengers.open) {
      setPassengers({ ...passengers, open: false });
    }
  };
  const incrementPassengers = () => {
    if (passengers.count < 4) {
      setPassengers({ ...passengers, count: passengers.count + 1 });
    }
  };

  const decrementPassengers = () => {
    if (passengers.count > 1) {
      setPassengers({ ...passengers, count: passengers.count - 1 });
    }
  };

  const handleSearch = () => {
    const style = {
      position: "top-right",
      autoClose: 3000,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    };
    setIsLoading(true);
    const departureValue = departure.current.value;
    const destinationValue = destination.current.value;
    if (!departureValue || !destinationValue) {
      toast.error("Veuillez remplir tous les champs", style);
    } else if (departureValue === destinationValue) {
      toast.error("Les localisations ne doivent pas être identiques", style);
    } else {
      navigate(
        "/search?departure=" +
          departureValue +
          "&destination=" +
          destinationValue +
          "&passengers=" +
          passengers.count
      );
      navigate(0);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  return (
    <section className="min-h-[85vh] ">
      <SearchBanner
        isLoaded={isLoaded}
        isLoading={isLoading}
        smScreen={smScreen}
        passengers={passengers}
        departure={departure}
        destination={destination}
        openPassengers={openPassengers}
        closePassengers={closePassengers}
        incrementPassengers={incrementPassengers}
        decrementPassengers={decrementPassengers}
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        handleSearch={handleSearch}
      />
      {isLoaded ? (
        !mapParams ? (
          <section className="p-6 text-center" onClick={closePassengers}>
            <p className="text-2xl font-bold text-primary ">MINICOM</p>
            <p className="md:text-lg ">
              Bénéficiez des tarifs personnalisés en fonction de votre lieu de
              résidence et de l’emplacement de l’établissement de votre enfant
            </p>

            <img
              src={taxi}
              alt="taxi"
              className="mx-auto scale-[0.8] md:scale-[0.9]"
            />
          </section>
        ) : (
          <MapContainer data={mapParams} departure={departure} destination={destination} passengers={passengers.count}/>
        )
      ) : (
        <section className="flex justify-center items-center h-[18vh]">
          <CircularProgress />
        </section>
      )}
      <ToastContainer />
    </section>
  );
};

export default SearchContainer;
