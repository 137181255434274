import React from "react";

import Modal from "@mui/material/Modal";
import Logo from "../../assets/logoPng.png";

const DeleteModal = ({
  openDelete,
  handleCloseDeleteModal,
  id,
  handleDelete,
}) => {
  return (
    <Modal
      open={openDelete}
      onClose={handleCloseDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white p-8 rounded-md w-[90vw] md:w-[75vw] lg:w-[50vw]">
      <div className="flex justify-center items-center my-4">
              <img
                src={Logo}
                className="grow-0 shrink-0  h-12 translate-x-[-2]"
                alt="Bëtacar"
              />
            </div>
        <p className="text-center text-primary font-bold  text-lg">
          Voulez vous vraiment supprimer cet enfant ?
        </p>
        <div className="flex flex-col md:flex-row justify-around [&>*]:mt-4 [&>*]:text-white hover:[&>*]:cursor-pointer  [&>*]:rounded-md [&>*]:py-1 [&>*]:px-2 [&>*]:text-center">
          <button
            className="bg-green-500 hover:bg-green-500/80  "
            onClick={() => {
              handleDelete(id);
              handleCloseDeleteModal()
            }}
          >
            Oui je veux supprimer
          </button>
          <button className="bg-red-500  hover:bg-red-500/80" onClick={
              handleCloseDeleteModal}>
            Non je ne veux plus
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
