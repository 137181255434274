import React from 'react';
import ProcabBanner from './ProCab/ProcabBanner';
import ProcabFunctionalities from './ProCab/ProcabFunctionalities';
import ProcabSubscription from './ProCab/ProcabSubscription';

const Procab = () => {
  return (
    <section id='Procab' className='w-[90%] mx-auto'>
        <ProcabBanner/>
        <ProcabFunctionalities/>
        <ProcabSubscription/>
    </section>
  )
}

export default Procab