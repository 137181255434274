import React,{useEffect} from "react";
import {useNavigate} from "react-router";
import Aos from "aos";
import "aos/dist/aos.css";
import Bus from "../../../assets/BusLarge.png";

const R2SBanner = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  return (
    <div className="flex flex-col md:flex-row md:h-[60vh] py-4 md:py-8 overflow-hidden ">
      <div data-aos="fade-right" className="md:w-[50%] lg:w-[40%] shrink-0 flex flex-col items-center md:items-start justify-center px-6 ">
        <p className="text-xl md:text-2xl text-primary font-extrabold text-center md:text-start">
          RIDE 2 SCHOOL (R2S)
        </p>
        <p className="mt-2 font-bold text-[#47585E] text-md md:text-lg text-center md:text-start">
          Arriver en retard est une catastrophe, être à l’heure c’est déjà être
          en retard, être en avance c’est être à l’heure.
        </p>
        <button className="py-2 px-4 bg-primary text-white rounded-md mt-4 hover:bg-primary/90 hover:cursor-pointer " onClick={() => {navigate("/search")}}>Personnaliser le prix</button>
      </div>
      <div  data-aos="fade-left" className="aos md:w-[50%] lg:w-[60%] shrink-0 flex justify-end items-center md:translate-x-[10vw]">
        <img src={Bus} alt="logo " className="shrink-0" />
      </div>
    </div>
  );
};

export default R2SBanner;
