import React from "react";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import HomeContainer from "./Container/HomeContainer";

const Home = () => {
  return (
    <section className="relative min-h-screen ">
      <Navbar  />
      <HomeContainer/>
      <Footer/>
    </section>
  );
};

export default Home;
