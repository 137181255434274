import React,{useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {  BsPerson } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import { FaRegHandshake } from "react-icons/fa";

const R2SFunctionnalities = () => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  const services = [
    {
      icon: <FiClock />,
      name: "Ponctualité",
      description:
        "Notre formule de transport d’élèves, étudiants et apprenants résoud vraiment  les soucis de retard à l’école le matin et les retours tardifs à la maison le soir après les classes. ",
    },
    {
      icon: <FaRegHandshake />,
      name: "Responsabilité",
      description: "Avec ce service, les parents sont sûrs que leurs enfants sont effectivement entrés à l’école, et qu’ils sont déjà rentrés à la maison.",
    },
    {
      icon: <BsPerson />,
      name: "Fiabilité",
      description: "Avec les enquêtes et garanties de sécurité et fiabilité vérifiées de nos partenaires, vous pouvez vous concentrer sur le reste de son éducation en toute quiétude. ",
    },
  ];

  return (
    <div>
      <p className="text-primary md:text-2xl font-extrabold text-center my-4">
        Pourquoi choisir Ride 2 School (R2S)
      </p>
      <div className="flex flex-wrap justify-center [&>*]:mx-4  mb-2">
        {services.map((service, id) => {
          return (
            <div
            data-aos="fade-up"
              key={id}
              className="aos flex flex-col items-center w-[250px] p-2 text-center my-2 text-[#47585E]"
            >
              <p className=" font-extrabold text-5xl ">{service.icon}</p>
              <p className="text-xl mt-1 font-bold">{service.name}</p>
              <p className=" mt-2 ">{service.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default R2SFunctionnalities;
