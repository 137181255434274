import React, { useState } from "react";
import { FaChild, FaCheck } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import axios from "axios";
import { registerParent, registerChild } from "../../utils/ApiRoute";
import ParentsInfo from "./Steps/ParentsInfo";
import ChildrenInfo from "./Steps/ChildrenInfo";
import ConfirmationStep from "./Steps/ConfirmationStep";
import Logo from "../../assets/logoPng.png";
import "react-toastify/dist/ReactToastify.css";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#016887",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#016887",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#016887",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#016887",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BsFillPersonFill />,
    2: <FaChild />,
    3: <FaCheck />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Informations sur le parent/tuteur",
  "Informations sur les enfants  ",
  "Souscription",
];

const SubscriptionsStepper = ({ type }) => {
  const [active, setActive] = useState(0);
  const [parentsInfo, setParentsInfo] = useState({
    username: "",
    numeroCni: "",
    email: "",
    telephone: "",
    quartier: "",
    ville: "",
    secteurHabitation: "",
    nombreEnfant: "",
    vieAvecToutMesEnfants: true,
    personneRecupereEnfants: "",
    detailPourDeposerEnfant: "",
  });
  const [childrenList, setChildrenList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const isParentInfoNull = () => {
    if (
      parentsInfo.username &&
      parentsInfo.numeroCni &&
      parentsInfo.email &&
      parentsInfo.telephone &&
      parentsInfo.quartier &&
      parentsInfo.ville &&
      parentsInfo.secteurHabitation &&
      parentsInfo.nombreEnfant &&
      parentsInfo.personneRecupereEnfants &&
      parentsInfo.detailPourDeposerEnfant
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleParentsInfoChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setParentsInfo({ ...parentsInfo, [name]: value });
  };

  const handleNext = () => {
    // console.log(parentsInfo);
    if (active === 0 && isParentInfoNull()) {
      console.log(parentsInfo);
      toast.error("Veuillez remplir tous les champs", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else if (active === 1 && childrenList.length < parentsInfo.nombreEnfant) {
      toast.error(
        `Vous devez renseigner ${parentsInfo.nombreEnfant} enfant(s)`,
        {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    } else {
      if (active === 1) {
        setOpenModal(true);
      } else {
        setActive((prev) => prev + 1);
      }
    }
  };

  const handleChildAdd = (data) => {
    let newChildrenList = childrenList;
    newChildrenList.push(data);
    setChildrenList(newChildrenList);
  };

  const handleChildModify = (data, id) => {
    let newChildrenList = childrenList;
    newChildrenList[id] = data;
    setChildrenList(newChildrenList);
  };

  const handleChildDelete = (id) => {
    let newChildrenList = childrenList;
    newChildrenList = newChildrenList.filter(
      (elt) => elt !== newChildrenList[id]
    );
    setChildrenList(newChildrenList);
  };

  const handleSubscription = async () => {
    setLoader(true);
    let success = true;
    const parentData = { ...parentsInfo, typeSouscription: type };
    try {
      const { data, status } = await axios.post(registerParent, parentData);

      console.log("parent data is", data);
      for (let childData of childrenList) {
        try {
          await axios.post(registerChild + `/${data.parent._id}`, childData);
        } catch (error) {
          success = false;
          setLoader(false);
          toast.error(
            "une erreur est survenue lors de l'ajout d'un enfant veuillez réessayer",
            {
              position: "top-right",
              autoClose: 8000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
          break;
        }
      }
      setOpenModal(false);
      if (success) {
        setActive((prev) => prev + 1);
        toast.success("souscription réussie", {
          position: "top-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      } 
    } catch (error) {
      console.log("error",error)
      setLoader(false);
      setOpenModal(false);
      toast.error(
        "une erreur est survenue lors de l'ajout du parent, veuillez réessayer",
        {
          position: "top-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }

    // setActive(prev => prev+1);*/
  };

  const StepComponent = () => {
    switch (active) {
      case 0:
        return (
          <ParentsInfo
            data={parentsInfo}
            handleChange={handleParentsInfoChange}
          />
        );
      case 1:
        return (
          <ChildrenInfo
            childrenNumber={parentsInfo.nombreEnfant}
            data={childrenList}
            addChild={handleChildAdd}
            modifyChild={handleChildModify}
            deleteChild={handleChildDelete}
          />
        );
      case 2:
        return <ConfirmationStep />;
      default:
        return <ParentsInfo />;
    }
  };

  return (
    <section className="my-8">
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={active}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <span>{label}</span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <div className="mb-4">{StepComponent()}</div>
          <div
            className={`flex justify-center [&>*]:mx-4 [&>*]:rounded-md hover:[&>*]:shadow hover:[&>*]:shadow-lg ${
              active === 2 && "hidden"
            }`}
          >
            <button
              className={`border border-secondary bg-white text-secondary px-4 py-1 ${
                active === 0 && "hidden"
              }`}
              onClick={() => setActive((prev) => prev - 1)}
            >
              Précédent
            </button>
            <button
              className="bg-primary border-primary  border  text-white  px-6 py-1 "
              onClick={() => handleNext()}
            >
              {active === 1 ? "Souscrire" : "Suivant"}
            </button>
          </div>
        </div>
      </Stack>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-transparent h-full w-full flex justify-center items-center">
          <div className="bg-white p-4 min-h-[35vh] min-w-[40vw] shadow-xl rounded-lg m-4 ">
            <div className="flex justify-center items-center">
              <img
                src={Logo}
                className="grow-0 shrink-0  h-12 translate-x-[-2]"
                alt="Bëtacar"
              />
            </div>
            <div className="my-4 px-3 text-center ">
              <p className="text-lg font-bold text-primary">
                Vous ètes sur le point de souscrire au programme{" "}
                <span className="text-secondary">Ride 2 School</span> de
                Betacare Drive
              </p>

              {loader ? (
                <div className="mt-4">
                  <CircularProgress
                    size="35px"
                    className=" my-2"
                    style={{ color: "#E66C2F" }}
                  />
                </div>
              ) : (
                <div className="flex flex-row justify-center [&>*]:mt-4 hover:[&>*]:cursor-pointer  [&>*]:rounded-md [&>*]:py-1 [&>*]:px-4 [&>*]:text-center">
                  <button
                    className="bg-transparent text-red-500 border border-red-500 hover:text-red-500/80  hover:border-red-500/80"
                    onClick={() => setOpenModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="bg-green-500 hover:bg-green-500/80 text-white ml-4 "
                    onClick={handleSubscription}
                  >
                    Souscrire
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </section>
  );
};

export default SubscriptionsStepper;
