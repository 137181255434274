import React from 'react';
import Navbar from '../../Layout/Navbar';
import ServicesContainer from "./Container/ServicesContainer";
import Footer from '../../Layout/Footer';

const Services = () => {
  return (
    <section className=''>
      <Navbar/>
      <ServicesContainer/>
      <Footer/>
      
    </section>
  )
}

export default Services