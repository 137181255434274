import React from 'react';
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";

const PassengersComponent = ({
    incrementPassengers,
    decrementPassengers,
    passengers,
  }) => {
    return (
      <div className="px-6 py-5 bg-white drop-shadow-xl rounded-lg flex justify-between items-center m-0" style={{zIndex:1000000}}>
        <p className="text-lg font-bold text-primary">Passagers</p>
        <div className="flex items-center [&>*]:ml-5">
          <BiMinusCircle
            className={` text-2xl hover:cursor-pointer ${
              passengers.count < 2 ? "text-slate-400" : "text-secondary"
            }`}
            onClick={() => {
              decrementPassengers();
            }}
          />
          <span className="text-[16px]">{passengers.count}</span>
          <BiPlusCircle
            className={` text-2xl hover:cursor-pointer ${
              passengers.count > 3 ? "text-slate-400" : "text-secondary"
            } `}
            onClick={() => {
              incrementPassengers();
            }}
          />
        </div>
      </div>
    );
  };
export default PassengersComponent