import React from "react";
import { CircularProgress } from "@mui/material";
import { BsPerson } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { Autocomplete } from "@react-google-maps/api";
import Modal from "@mui/material/Modal";
import Input from "../../Components/BaseComponents/Input";
import PassengersComponent from "./PassengersComponent";

const SearchBanner = ({
  isLoaded,
  isLoading,
  smScreen,
  departure,
  destination,
  passengers,
  openModal,
  openPassengers,
  closePassengers,
  incrementPassengers,
  decrementPassengers,
  handleClose,
  handleSearch,
}) => {
  return (
    <section className=" bg-searchBanner bg-cover bg-no-repeat bg-center h-auto  md:h-[250px] ">
      <div className="relative w-[90%] mx-auto py-3 md:py-6 text-center flex flex-col justify-start md:justify-between h-full">
        <p
          className="text-white text-2xl lg:text-5xl font-extrabold drop-shadow-xl"
          onClick={closePassengers}
        >
          Personnaliser son prix
        </p>
        <div
          className="  md:mt-4 md:mt-0 md:grid  md:grid-cols-9 md:gap-0 [&>*]:w-full overflow-hidden "
          onClick={closePassengers}
        >
          {isLoaded && (
            <Autocomplete className="md:col-span-3">
              <Input
                iconStart
                icon={
                  <CiLocationOn
                    className={`${smScreen ? "text-lg" : "text-2xl"}`}
                  />
                }
                placeholder="Départ"
                style={{
                  backgroundColor: "white",
                  borderRadius: smScreen ? "10px" : "10px  0px 0px 10px ",
                }}
                small={smScreen ? true : false}
                innerRef={departure}
              />
            </Autocomplete>
          )}

          {isLoaded && (
            <Autocomplete className="md:col-span-3">
              <Input
                iconStart
                icon={
                  <CiLocationOn
                    className={`${smScreen ? "text-lg" : "text-2xl"}`}
                  />
                }
                placeholder="Destination"
                style={{
                  backgroundColor: "white",
                  borderRadius: smScreen ? "10px" : "0px",
                }}
                small={smScreen ? true : false}
                innerRef={destination}
              />
            </Autocomplete>
          )}
          <div
            className={`bg-white border border-slate-200  mt-2 mb-1 hover:border hover:border-primary flex items-center pl-1.5 hover:cursor-pointer
               ${!isLoaded && "hidden"} ${smScreen && "rounded-lg py-1.5"} `}
            onClick={openPassengers}
          >
            <BsPerson
              className={`mr-1 text-[#A9A9A9]  ${
                smScreen ? "text-lg" : "text-2xl"
              }`}
            />
            <p className="text-[16px] text-ellipsis">
              {passengers.count} Passagers
            </p>
          </div>

          <p
            className={` bg-primary text-white mt-2 mb-1 text-center flex items-center justify-center col-span-2 text-lg font-medium hover:cursor-pointer hover:bg-sky-700 ${
              !isLoaded && "hidden"
            }  ${smScreen ? "rounded-lg py-1.5" : "rounded-r-xl "}`}
            onClick={handleSearch}
          >
            {" "}
            {isLoading ? (
              <CircularProgress size={28} sx={{ color: "#E66C2F" }} />
            ) : (
              "Rechercher"
            )}
          </p>
        </div>
        {passengers.open && (
          <div className={`absolute bottom-[-23%] right-[23%] w-[350px]  `}>
            <PassengersComponent
              passengers={passengers}
              incrementPassengers={incrementPassengers}
              decrementPassengers={decrementPassengers}
            />
          </div>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  outline-none">
          <PassengersComponent
            passengers={passengers}
            incrementPassengers={incrementPassengers}
            decrementPassengers={decrementPassengers}
          />
        </div>
      </Modal>
    </section>
  );
};

export default SearchBanner;
