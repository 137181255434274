import React from 'react';
import { BsPlusCircle, BsX, BsPencilFill } from "react-icons/bs";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Logo from "../../assets/logoPng.png";

const AddModifyModal = ({open, handleClose, data, handleChange, handleAdd, handleModify, modify}) => {
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white p-8 rounded-md w-[90vw] md:w-[75vw] lg:w-[50vw]">
        <div className="flex justify-center items-center my-4">
              <img
                src={Logo}
                className="grow-0 shrink-0  h-12 translate-x-[-2]"
                alt="Bëtacar"
              />
            </div>
          <BsX
            className="absolute top-[8%] right-[0%] translate-x-[-50%] translate-y-[-50%] text-secondary text-3xl font-bold hover:cursor-pointer hover:text-secondary/80"
            onClick={handleClose}
          />
          <div className="text-center text-primary font-bold  text-xl">
            Ajouter un enfant
          </div>
          <p
            className="mb-4  text-center text-[#8B7F7F]"
          >
            Veuillez remplir les champs çi-dessous
          </p>
         
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5   [&>*]:my-1 ">
            {data.map((info, id) => {
              return (
                <TextField
                  key={id}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label={info.label}
                  name={info.name}
                  value={info.value}
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                />
              );
            })}
          </div>
          <button
            className="flex items-center mx-auto mt-4 bg-green-600 text-white px-3 py-1 rounded-md mt-2 hover:cursor-pointer hover:bg-green-500"
            onClick={() => {!modify? handleAdd() :handleModify()}}
            disabled={false}
          >
            <BsPlusCircle className={`${modify? "hidden":"block"} mr-1 `}/> 
            <BsPencilFill className={`${!modify? "hidden":"block"} mr-1 `}/> 
            <span>{modify?"Modifier":"Ajouter"}</span>
          </button>
        </div>
      </Modal>
  )
}

export default AddModifyModal