import React from "react";
import { BrowserRouter as Route } from "react-router-dom";
import Router from "./Router/Router";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop"

const App = () => {
  return (
    <section>
      <Route>
        <ScrollToTop/>
        <Router />
      </Route>
    </section>
  );
};

export default App;
