import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CommercialModal from "../CommercialModal";

const subscriptionTypes = [
  {
    name: "Minicom",
    advantages: [
      "Achat de places par taxi (4)",
      "Tarif personnalisable",
      "Trajet direct",
      "Comfortable",
      "Sécurisé",
    ],
    default: false,
  },
  /*   {
    name: "Privé",
    price: "130 000",
    advantages: [
      "Reservé pour un seul enfant ou famille",
      "Organisation libre du client",
      "Trajet direct",
      "Grand confort et sécurisé",
    ],
    default: false,
  }, */
];

const R2SSubscription = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);

  const handleNavigate = () => {
    navigate("/search");
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    navigate("/search");
  };

  return (
    <div className="mt-8 text-center">
      <p className="text-primary md:text-2xl font-extrabold  my-4">
        Soucription au service
      </p>
      <p className=" text-[#47585E] font-medium">
        Choisissez l’option de souscription idéale pour votre enfant
      </p>
      <div
        data-aos="fade-up"
        className="aos flex flex-wrap justify-around mt-6 mb-2 md:w-[80%] lg:w-[70%] mx-auto "
      >
        {subscriptionTypes.map((subscription, id) => {
          return (
            <div
              key={id}
              className="flex flex-col w-[300px] border border-[#F5F5F5] px-4 py-6  rounded-xl my-2 shadow shadow-md"
              style={
                subscription.default
                  ? { backgroundColor: "white" }
                  : {
                      background:
                        "linear-gradient(180deg, #01B7EE 0%, #0082A9 100%)",
                    }
              }
            >
              <p
                className={`${
                  subscription.default ? "text-primary " : "text-white "
                } text-3xl font-extrabold `}
              >
                {subscription.name}
              </p>{" "}
              <hr
                className={`text-primary border-2 mt-1  w-20 mx-auto ${
                  subscription.default ? "border-white" : "border-[#FF5B09] "
                }`}
              />
              <div className="mt-4">
                {subscription.advantages.map((advantage, id) => {
                  return (
                    <p
                      key={id}
                      className={`${
                        subscription.default ? "text-[#7C6C6C]" : "text-white"
                      } flex items-center text-md text-start leading-8 `}
                    >
                      <BsCheckLg
                        className={`${
                          subscription.default
                            ? "text-green-500"
                            : "text-green-300"
                        } ${
                          advantage === "" && "text-white"
                        }  mr-2  my-1 text-xl`}
                      />{" "}
                      {advantage}
                    </p>
                  );
                })}
              </div>
              <p
                className="bg-[#FF5B09] hover:cursor-pointer hover:bg-[#FF5B09]/90 mt-4 text-white text-center w-full p-2 rounded rounded-md"
                onClick={handleNavigate}
              >
                Personnaliser le prix
              </p>
            </div>
          );
        })}
      </div>
      <p className="text-center font-bold text-[17px] ">
        <span className="text-[#293134] ">
          Besoin d'un taxi spécial pour un plus grand confort ?{" "}
        </span>{" "}
        <span
          className="text-secondary hover:underline hover:cursor-pointer"
          onClick={() => {
            navigate("/subscription/prive");
          }}
        >
          Demander un devis ici
        </span>
      </p>
      <CommercialModal
        open={open}
        isLoading={isLoading}
        handleClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default R2SSubscription;
