import React from "react";
import { motion } from "framer-motion";
import accueil from "../../../assets/Accueil.png";
import accueilSmall from "../../../assets/sideAccueil.png";
import Logo from "../../../assets/betacar.png";

const diceContainerVariants = {
  hidden:{
    y:'-100vw',
    opacity:0
  },

  visible:{
    opacity:1,
    y:0 ,
    transition:{
      type:'tween',
      duration:1.2
    }
  }
}

const containerVariants = {
  hidden:{
    x:'-100vw',
    opacity:0
  },

  visible:{
    opacity:1,
    x:0 ,
    transition:{
      type:'tween', 
      duration:1.2
    }
  }
}
 

const HomeContainer = () => {
 

  return (
    <div className=" z-0 relative overflow-hidden ">
      <motion.div
        variants={diceContainerVariants}
        initial="hidden"
        animate="visible"
        className="hidden md:block absolute  h-[90vh] flex items-start md:items-center top-0 right-[-6vw] md:right-5 lg:right-10 lg:right-20"
      >
        <img
          src={accueil}
          alt="bg dice"
          className=" h-[400px] w-[200px] md:h-[500px]  md:w-[250px] lg:h-[530px] lg:w-[275px] "
        />
      </motion.div>
      <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
       className="flex flex-col mt-8 md:mt-[0px] md:translate-y-[-5vh] items-center md:items-start justify-start md:justify-center h-[90vh] w-[90%] md:w-[85%] lg:w-[75%] mx-auto">
        <img
          src={Logo}
          alt="logo"
          className="w-[220px] h-[120px] md:w-[240px] md:h-[130px] lg:w-[250px] lg:h-[150px] md:translate-x-[-1vw]"
        />
        <div className="text-center md:text-start">
          <p className="text-xl md:text-2xl lg:text-3xl text-[#8B7F7F] font-semibold ">
            Simplifiez vos deplacements
          </p>
          <p className=" md:w-[430px] lg:w-[500px] mt-2 text-black font-medium">
            “Bëtacar Drive n’est pas une plateforme ordinaire qui gère juste le
            transport et la sécurité des personnes. Elle traite d’autres aspects
            qui font d’elle un écosystème communautaire soudé strict et
            environnemental.”
          </p>
          <p className="mt-2">
            <span className="text-secondary font-semibold">Frankee Seme</span>.{" "}
            <span className=" font-roboto text-primary">
              Pdg, Bëtacar drive
            </span>
          </p>
        </div>
        <img src={accueilSmall} alt="side accueil" className="md:hidden " />
      </motion.div>
    </div>
  );
};

export default HomeContainer;
