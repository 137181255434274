import React from "react";
import HeroSection from "../../../Components/Services/HeroSection";
import ServicesList from "../../../Components/Services/ServicesList";
import Ride2School from "../../../Components/Services/Ride2School";
import BlueBanner from "../../../Components/Services/BlueBanner";
import OrangeBanner from "../../../Components/Services/OrangeBanner";
import Betacar from "../../../Components/Services/Betacar";
import Procab from "../../../Components/Services/Procab";

const ServicesContainer = () => {
  return (
    <section className="">
      <HeroSection />
      <ServicesList />
      <Ride2School />
      <BlueBanner />
      <Procab />
      <OrangeBanner />
      <Betacar />
    </section>
  );
};

export default ServicesContainer;
