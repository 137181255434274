import React from 'react';
import {Link} from "react-router-dom";

const ProcabSubscription = () => {
  return (
    <div>
      <p className="text-primary md:text-2xl font-extrabold text-center my-4">
        Souscrire à ProCab
      </p>
      <p className=" md:w-[85%] lg:w-[70%] mx-auto text-center text-[#47585E] font-medium">
      Demander un devis via <Link to="https://www.contact@betacardrive.com" className="text-primary hover:underline hover:underline-offset-1">contact@betacardrive.com</Link>, service dédié aux entreprises ou aux clients premium.
      </p>
    </div>
  )
}

export default ProcabSubscription