import React,{useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Service1 from "../../assets/service1.png";
import Service2 from "../../assets/service2.png";
import Service3 from "../../assets/service3.png";

const services = [
  {
    image:Service1,
    name:"Ride 2 School (R2S)",
    description:"Service de gestion des transports scolaires pour élèves et étudiants",
    id:"R2S"
  },
  {
    image:Service2,
    name:"ProCab",
    description:"Service de transports pour travailleurs et entreprises",
    id:"Procab"
  },
  {
    image:Service3,
    name:"Bëtacar",
    description:"Service de gestion de transport en commun des particuliers",
    id:"Betacar"
  },
]

const ServicesList = () => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  return (
    <div className="pt-12 w-[90%] mx-auto">
      <p className="text-primary text-2xl font-bold text-center my-4">
        Liste des services offerts
      </p>
      <p className="text-center  text-[#47585E] font-medium ">
        Dans l’optique d’une meilleure cohésion sociale et un impact positif sur
        l’environnement et l’économie, Bëtacar Drive vous propose ces services.
      </p>
      <div 
      data-aos="fade-up"
      className="aos flex flex-wrap justify-center [&>*]:mx-4 mt-6 mb-2">
        {
          services.map((service, id) => {
            return(
              <div key={id} className="flex flex-col items-center w-[250px] border border-[#ADADAD] p-2 text-center rounded-xl my-2">
                <img src={service.image} alt="service representation" className="w-[100px] h-[100px] md:w-[150px] md:h-[150px]"/>
                <p className="text-primary font-bold text-xl mt-1">{service.name}</p>
                <p className="text-[#303D41] text-md">{service.description}</p>
                <p className="text-secondary text-sm mt-2 hover:cursor-pointer hover:underline hover:underline-offset-1" onClick={() => {document.getElementById(service.id).scrollIntoView()}}>En savoir plus</p>
              </div>
            )
          })
        }
      </div>
      
    </div>
  );
};

export default ServicesList;
