import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router";

const containerVariants = {
  hidden: {
    opacity: 0,
  },

  visible: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1,
    },
  },
};

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const sidebarLinks = [
    {
      name: "Accueil",
      path: "/",
    },
    {
      name: "Nos services",
      path: "/services",
    },
    {
      name: "Nos partenaires",
      path: "/partenaires",
    },
    {
      name: "Nous rejoindre",
      path: "/nous-rejoindre",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  return (
    <nav className="py-4 bg-white/90 sticky top-0  shadow shadow-sm" style={{ zIndex: 8 }}>
      <div className="w-[90%] mx-auto flex justify-between items-center">
        <p
          className="font-roboto text-primary text-lg md:text-xl font-medium hover:cursor-pointer "
          onClick={() => navigate("/")}
        >
          <span className="text-primary ">Bëtacar</span>{" "}
          <span className="text-secondary ">Drive</span>
        </p>
        <p
          className="text-sm md:text-lg text-secondary font-medium hover:cursor-pointer hover:underline hover:underline-offset"
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          Explorer
        </p>
      </div>
      {openSidebar && (
        <div
          className="absolute top-0 left-0 h-[100vh] w-full bg-primary/20 flex"
          style={{ zIndex: 10000 }}
        >
          <div
            className="w-full h-[100vh] bg-primary/20 "
            onClick={() => {
              setOpenSidebar(false);
            }}
          ></div>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="h-screen bg-primary/90 w-[200px] md:w-[250px] py-4 shrink-0"
          >
            <p className="text-xl text-white font-semibold text-center px-4 ">
              Bëtacar Drive
            </p>
            <div className="mt-8">
              {sidebarLinks.map((elt, id) => {
                return (
                  <p
                    key={id}
                    className={`${
                      location.pathname === elt.path
                        ? "bg-secondary/80"
                        : "bg-transparent hover:bg-secondary/30 hover:cursor-pointer"
                    } text-center py-2 my-1 text-white`}
                    onClick={() => {
                      navigate(elt.path);
                    }}
                  >
                    {" "}
                    {elt.name}{" "}
                  </p>
                );
              })}
            </div>
          </motion.div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
