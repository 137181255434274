import React from "react";
import Logo from "../../assets/logoPng.png";

const BlueBanner = () => {
  return (
    <div className="min-h-[28vh] my-8 bg-[#E7F9FF] overflow-hidden p-6">
      <img src={Logo} alt="betacar" className="grow-0 shrink-0  h-12 mx-auto" />
      <p className="text-center text-primary font-bold text-lg  leading-7">
        <span className="text-5xl ">&#8220;</span>{" "}
        <span>
          Le trajet qui forge des amitiés éveille des esprits curieux et
          <br /> ouvre les portes d’un avenir prometteur{" "}
        </span>
      </p>
    </div>
  );
};

export default BlueBanner;
