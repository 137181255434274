import React,{useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router';
import SubscriptionsStepper from '../../../Components/Subscriptions/SubscriptionsStepper';

const services =  [{
  name: "minicom",
  price: "25 000"
},
{
  name: "prive",
  price: "130 000"
}];

const SubscriptionsContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [subscriptionOption, setSubscriptionOption] = useState("");
   
    useEffect(() => {
        const subscriptionType = services.find((elt) => `/subscription/${elt.name}`=== location.pathname);
        if(!subscriptionType){navigate("/")}
        setSubscriptionOption(subscriptionType);
    },[location.pathname, navigate])
  return (
    <section className='min-h-[85vh] w-[90%] mx-auto md:py-4'>
        <p className="text-primary text-center text-lg md:text-xl font-bold">Souscription au service Ride 2 School (R2S)</p>
        <p className='text-[#47585E] text-center text-sm md:text-[16px] mt-2'>Vous êtes sur le point de souscrire à l'option <span className='text-secondary uppercase font-bold'>{subscriptionOption.name} ({subscriptionOption.price} Fcfa)</span>, veuillez renseigner les informations çi-dessous </p> 
        <SubscriptionsStepper type={subscriptionOption.name}/>
    </section>
  )
}

export default SubscriptionsContainer