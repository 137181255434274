import React from 'react';
import R2SBanner from "./Ride2School/R2SBanner";
import R2SFunctionnalities from './Ride2School/R2SFunctionnalities';
import R2SHowItWorks from './Ride2School/R2SHowItWorks';
import R2SSubscription from './Ride2School/R2SSubscription';

const Ride2School = () => {
  return (
    <section id="R2S" className="w-[90%] mx-auto">
      <R2SBanner/>
      <R2SFunctionnalities/>
      <R2SHowItWorks/>
      <R2SSubscription/>
    </section>
  )
}

export default Ride2School